import React, {Component} from 'react';

import {Outlet} from "react-router-dom";
import {Column, Content, Grid, Row} from "carbon-components-react";



/**
 * Handles the Page theming determining whether we are staff or student and updating the state for the navigation brs
 */
class CapacityLayout extends Component {

    componentDidMount() {
    }


    render() {
        return (
            <>

                <div className = "mainLogoContainer">


{/*			<span className="nhsuk-header__link">*/}
{/*				<svg className="nhsuk-logo" role="img"*/}
{/*                     aria-labelledby="headerNHSlogo" focusable="false" viewBox="0 0 40 16">*/}
{/*	<title id="headerNHSlogo">NHS Logo</title>*/}
{/*	<path className="nhsuk-logo__background" d="M0 0h40v16H0z"></path>*/}
{/*	<path className="nhsuk-logo__text"*/}
{/*          d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"></path>*/}
{/*</svg>*/}
{/*				<span className="nhsuk-organisation-name">Health Education England</span>*/}
{/*				<span className="nhsuk-organisation-descriptor">Radiography Learner Capacity Tool</span>*/}
{/*			</span>*/}


                    <div className ="mainLogoContainerRestrictWidth">
                    <img className = "nhsLogo" src = "/i/radiographylearnercapacitytoolbannerhee.png" />
                        <a href = "https://www.aecc.ac.uk" target = "_new"><img className = "aeccLogo" src = "/i/AECCLogo_inverse.png" /></a>

                    {/*<p>Welcome to a placement capacity model (alpha). Our intent here is to provide a guide to placement numbers based on a few questions about a given facility and group of staff</p>*/}
                    </div>
                </div>

                <Content>
                    <Outlet/>
                </Content>

            </>
        );
    }
}


export default CapacityLayout;
