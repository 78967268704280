import {Stack} from "@fluentui/react/lib/Stack";
import {Callout, DefaultButton, IconButton, TooltipDelay, TooltipHost} from "@fluentui/react";
import * as PropTypes from "prop-types";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {Information} from "@carbon/icons-react/next";
import React, {Component} from "react";
import {Content} from "carbon-components-react";
import LabelWithInfo from "./LabelWithInfo";







export default class BasePanel extends Component {


    constructor(props) {
        super(props);
    }


    labelRender = (props) => {
        return (
            <LabelWithInfo id = {props.labelId} {...props} />
        )
    }
    componentDidMount() {

    }




};