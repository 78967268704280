import React from 'react';



/** returns a formatted for display version of a minute value **/
export const generateShortTimeString = (minutes) => {
    let totalHours = generateHours(minutes);
    let additionalMinutes = generateAdditionalMinutes(minutes);

    let totalDays = generateDays(totalHours);
    let additionalHours = generateAdditionalHours(totalHours);

    if (!isNaN(totalDays) && totalDays > 0 ) {
        return (`${totalDays}D ${additionalHours}H ${additionalMinutes}M`)
    } else {
        return (`${totalHours}H ${additionalMinutes}M`)
    }
}

/** Returns the number of full hours these minutes contain **/
const generateHours = (minutes) => {
    if (!isNaN(minutes) && minutes > 0) {
        return Math.trunc(minutes / 60);
    } else {
        return 0;
    }
}

/** Returns the number of minutes that can;t be wholly contained within an integer number of hours**/
const generateAdditionalMinutes = (minutes) => {
    if (!isNaN(minutes) && minutes > 0) {
        return Math.trunc(minutes % 60);
    } else {
        return 0;
    }
}


/** Returns the number of full days these hours contain **/
const generateDays = (hours) => {
    if (!isNaN(hours) && hours > 0) {
        return Math.trunc(hours / 24);
    } else {
        return 0;
    }
}

/** Returns the number of hours that can;t be wholly contained within an integer number of days**/
const generateAdditionalHours = (hours) => {
    if (!isNaN(hours) && hours > 0) {
        return Math.trunc(hours % 24);
    } else {
        return 0;
    }
}


