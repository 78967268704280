import React, {Component} from "react";
import BasePanel from "../Util/BasePanel"
import * as PropTypes from "prop-types";
import {
    DialogType,
    Panel,
    Slider, TextField,
} from "@fluentui/react";
import {Button, ButtonSet} from "carbon-components-react";

import {Stack} from '@fluentui/react/lib/Stack';


/**
 * Set up some sane defaults for our form
 */
const defaultState = {
    courseName: "",
    placementLength: 8,
    ultrasoundHours: 0,
    mriHours:0,
    xrayHours:0,
    ctHours:0,
    theatreHours:0,
    fluoroscopyHours:0,
    nuclearHours:0,
    mammographyHours:0,
    otherHours:0
}

/**
 * Overrides some fluent defaults - this is surprisingly difficult to do in css much easier here
 */
const spinStyles = {spinButtonWrapper: {width: 80}};

const courseNameTooltip = {
    "onRenderContent": () => (
        <div className = "tooltipContainer">
            <p>Provide a name for this new course.</p>
        </div>
    ),
};

/** override the default gap between elements, this is abusing the MS override system rather than have to implement the
 *  full stylesheet */
const stackTokens = {childrenGap: 10};


export default class AddCourseLayoutPanel extends BasePanel {

    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    formatHours(value) {
        switch(value) {
            case 0:
                return "None"
            case 1:
                return value + " hr";
            default:
                return value + " hrs";
        }
    }

    formatWeeks(value) {
        if (value === 1) {
            return value + " wk";
        } else {
            return value + " wks";
        }
    }


    footerContent = () => {
        return (
            <ButtonSet className="positionRight">
                <Button type = "button" onClick = {()=>{
                    this.addCourse();
                }}>
                    Add course
                </Button>
            </ButtonSet>
        )
    }

    addCourse() {


        console.log("current courses:", this.props.courses)

        const {
            courseName,
            placementLength,
            ultrasoundHours,
            mriHours,
            xrayHours,
            ctHours,
            theatreHours,
            fluoroscopyHours,
            nuclearHours,
            mammographyHours,
            otherHours,
        } = this.state;

        let courseDetails = {
            location: courseName,
            placementLengthWeeks: placementLength,
            services: {
                Ultrasound: ultrasoundHours,
                MRI: mriHours,
                XRay: xrayHours,
                CT: ctHours,
                Theatre: theatreHours,
                Fluoroscopy: fluoroscopyHours,
                Nuclear: nuclearHours,
                Mammography: mammographyHours,
                Other: otherHours,
            }
        }



        //this is a regular array so we can push

        this.props.setCourses(
            [...this.props.courses, courseDetails]
        )

        console.log("new courses", this.props.courses)

        //Clear the form now we've completed things
        this.clearForm();

        //toggle the visibility of the form
        this.props.onDismiss();


    }

    /**
     * Resets the form elements to default states
     */
    clearForm() {
        this.setState(defaultState)
    }




    render() {

        return (
                <Panel
                    isOpen={this.props.open}
                    onDismiss={this.props.onDismiss}
                    isBlocking={true}
                    closeButtonAriaLabel="Close Panel"
                    onRenderFooterContent={this.footerContent}
                    isFooterAtBottom={true}
                    isLightDismiss={true}
                >
                    <div className="panelContainer">

                <h3>Add new course</h3>

                    <p>
                        Your course provider may require set hours experiencing particular disciplines. You can define those requirements here
                    </p>

                    <Stack tokens={stackTokens}>

                            <TextField
                                onRenderLabel={this.labelRender}
                                label="Course name"
                                tooltip={courseNameTooltip}
                                disabled={false}
                                onChange = {(event, value) => {
                                    this.setState({
                                        courseName: value
                                    })
                                }}
                                required
                            />

                        <p>over how many <strong>weeks</strong> should a placement take place</p>

                        <Slider
                            label="Length of placement (weeks)"
                            min={1}
                            max={52}
                            step={1}
                            showValue={true}
                            snapToStep={true}
                            valueFormat={this.formatWeeks}
                            onChanged={(event, value) => {
                                this.setState({
                                    placementLength: value
                                })
                            }}
                        />


                        <p>How many <strong>hours</strong> in each discipline is a learner expected to experience</p>

                        <Slider label="Ultrasound"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        ultrasoundHours: value
                                    })
                                }}
                        />

                        <Slider label="MRI"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        mriHours: value
                                    })
                                }}
                        />

                        <Slider label="XRay"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        xrayHours: value
                                    })
                                }}
                        />

                        <Slider label="CT"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        ctHours: value
                                    })
                                }}
                        />

                        <Slider label="Theatre"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        theatreHours: value
                                    })
                                }}
                        />
                        <Slider label="Fluoroscopy"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        fluoroscopyHours: value
                                    })
                                }}
                        />
                        <Slider label="Nuclear"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        nuclearHours: value
                                    })
                                }}
                        />

                        <Slider label="Mammography"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        mammographyHours: value
                                    })
                                }}
                        />

                        <Slider label="Other"
                                min={0}
                                max={1000}
                                step={1}
                                showValue={true}
                                snapToStep={true}
                                valueFormat={this.formatHours}
                                onChanged={(event, value) => {
                                    this.setState({
                                        otherHours: value
                                    })
                                }}
                        />
                    </Stack>


                    </div>
            </Panel>


        )


    }
}

AddCourseLayoutPanel.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,

    courses: PropTypes.array,
    setCourses: PropTypes.func



/*
    onOverwrite: PropTypes.func,
    onMerge: PropTypes.func,
    onCancel: PropTypes.func,

    title: PropTypes.string,
    subText: PropTypes.string,

    open: PropTypes.any,

    children: PropTypes.node
*/
};