import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton} from "@fluentui/react";

export default class WarningDialog extends Component {



    render() {

        const modelProps = {
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        };
        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: this.props.title,
            subText: this.props.subText,
        };


        return (
        <Dialog
            hidden={!this.props.open}
            onDismiss={this.props.onCancel}
            dialogContentProps={dialogContentProps}
            modalProps={modelProps}
        >
            <>

            {this.props.children}

            <DialogFooter>
                <PrimaryButton
                    onClick={this.props.onOk} text="Ok" />
                {/*}               <PrimaryButton
                    onClick={this.props.onMerge} text="Merge" /> */}
            </DialogFooter>
            </>
        </Dialog>
        )


    }
}

WarningDialog.propTypes = {

    onOk: PropTypes.func,
    title: PropTypes.string,
    subText: PropTypes.string,
    open: PropTypes.any,
    children: PropTypes.node
};

/*
Button Types:
command
command = 4
compound
compound = 3
default
default = 6
hero
hero = 2
icon
icon = 5
normal
normal = 0
primary
primary = 1


 */