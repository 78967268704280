import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
    Panel,
    Stack,
} from "@fluentui/react";


import {Button, ButtonSet, FileUploader} from "carbon-components-react";



/**
 * Overrides some fluent defaults - this is surprisingly difficult to do in css much easier here
 */
const spinStyles = {spinButtonWrapper: {width: 80}};

/** override the default gap between elements, this is abusing the MS override system rather than have to implement the
 *  full stylesheet
 **/
const stackProps = {childrenGap: "1rem"};



export default class LoadDialogPanel extends Component {

    constructor(props) {
        super(props);
    }


    handleUpload() {

        let fileInputNode = document.getElementsByName("saveFileLocation").item(0);

        let file = fileInputNode.files[0];

        let fileReader = new FileReader();

        fileReader.addEventListener("load", () => {
            let inData = fileReader.result;

            let parsedInData = JSON.parse(inData)

            let courses = parsedInData[1].courses;
            let services = parsedInData[1].services;
            let serviceTypes = parsedInData[1].serviceTypes;
            let events = parsedInData[1].events;

          //  this.props.setServiceTypes(serviceTypes);
          //  this.props.setEvents(events);


            // this.props.setCourses((prev) => {
            //     return {
            //         courses,
            //     }
            // })


            this.props.setServices(services)

            this.props.setCourses(courses)

            this.props.setServiceTypes(serviceTypes);

            for (let i = 0; i < events.length; i++) {
                events[i].start = new Date(events[i].start)
                events[i].end = new Date(events[i].end)
            }


            console.log("setting events", events);

            this.props.setEvents(events);



            //console.log("parsedData", parsedInData);


//            console.log("loaded:", JSON.parse(fileReader.result));
        }, false)

        fileReader.readAsText(file);



        // FileService.uploadFile(
        //     form.portfolioUploader.files[0],
        //     form.documentDescription.value,
        //     form.selectedSubmissionSection.value,
        // )

        this.props.onDismiss();

    }

    footerContent = () => {
        return (
            <ButtonSet className="positionRight">
                <Button type="primary" onClick={() => {
                    this.handleUpload()
                  //  this.props.onDismiss();
                }}>
                    Load
                </Button>
            </ButtonSet>
        )
    }

    render() {
        return (
            <Panel
                isOpen={this.props.open}
                onDismiss={this.props.onDismiss}
                isBlocking={true}
                closeButtonAriaLabel="Close Panel"
                onRenderFooterContent={this.footerContent}
                isFooterAtBottom={true}
                isLightDismiss={true}
            >
                <div className="panelContainer">
                    <h3>Load data</h3>
                    <p>If you've previously saved a session or have been provided with a session from a colleague you can choose to import that session here. You'll be asked to select the file on disk and this webapp wil then read and use that file for its default settings. Note no data is transmitted outside of your browser here, processing takes place locally.</p>

                    <Stack tokens={stackProps}>




                        <FileUploader
                            labelTitle="Select save file"
                            labelDescription="locate your previously saved model file for import, by default this will have been placed in your browser's download folder and called capacityPlanner.sv1"
                            buttonLabel="Select file"
                            buttonKind="primary"
                            size="md"
                            filenameStatus="edit"
                            role="button"
                            accept={[ '.sv1']}
                            multiple={false}
                            disabled={false}
                            iconDescription="Delete file"
                            name="saveFileLocation"
                        />


                        {/*<SpinButton*/}
                        {/*    label="Weeks to replicate *"*/}
                        {/*    defaultValue={8}*/}
                        {/*    min={1}*/}
                        {/*    max={52}*/}
                        {/*    step={1}*/}
                        {/*    incrementButtonAriaLabel="Increase value by 1"*/}
                        {/*    decrementButtonAriaLabel="Decrease value by 1"*/}
                        {/*    styles={spinStyles}*/}
                        {/*    onChange={(event, value) => {*/}
                        {/*        this.props.setReplicationWeeks(parseInt(value));*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Stack>
                </div>
            </Panel>
        )
    }
}

LoadDialogPanel.propTypes = {
    //Is the panel open
    open: PropTypes.bool,
    //Function to call on close button click
    onDismiss: PropTypes.func,

    //The save structure we're using
    dataStructure: PropTypes.object,
    //Function to assign the save structure to the state

    setCourses: PropTypes.func,
    setServiceTypes: PropTypes.func,
    setServices: PropTypes.func,
    setEvents: PropTypes.func,


};


