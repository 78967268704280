import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
    Panel,
    SpinButton, Stack,
} from "@fluentui/react";


import {Button, ButtonSet} from "carbon-components-react";


/**
 * Overrides some fluent defaults - this is surprisingly difficult to do in css much easier here
 */
const spinStyles = {spinButtonWrapper: {width: 80}};

/** override the default gap between elements, this is abusing the MS override system rather than have to implement the
 *  full stylesheet
 **/
const stackProps = {childrenGap: "1rem"};



export default class SaveDialogPanel extends Component {

    constructor(props) {
        super(props);
    }


    handleSave() {
        console.log("handling save");
        let dataStructure = this.props.getDataStructure()

        let file = new Blob([JSON.stringify(dataStructure)], {type:"text/json"})

        let a = document.createElement("a")
        let url = URL.createObjectURL(file);
        a.href = url;
        a.download = "capacityPlanner.sv1";

        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);

        console.log(dataStructure)
    }

    footerContent = () => {
        return (
            <ButtonSet className="positionRight">
                <Button type="primary" onClick={() => {
                    this.handleSave();

                    this.props.onDismiss();
                }}>
                    Save
                </Button>
            </ButtonSet>
        )
    }

    render() {
        return (
            <Panel
                isOpen={this.props.open}
                onDismiss={this.props.onDismiss}
                isBlocking={true}
                closeButtonAriaLabel="Close Panel"
                onRenderFooterContent={this.footerContent}
                isFooterAtBottom={true}
                isLightDismiss={true}
            >
                <div className="panelContainer">
                    <h3>Save data</h3>
                    <p>You have here the opportunity to save the setup of your instance here. You can send this save file to colleagues or use it yourself to restore a previous session. Select the save option below and choose where you wish to save the file.</p>

                    <Stack tokens={stackProps}>


                        {/*<SpinButton*/}
                        {/*    label="Weeks to replicate *"*/}
                        {/*    defaultValue={8}*/}
                        {/*    min={1}*/}
                        {/*    max={52}*/}
                        {/*    step={1}*/}
                        {/*    incrementButtonAriaLabel="Increase value by 1"*/}
                        {/*    decrementButtonAriaLabel="Decrease value by 1"*/}
                        {/*    styles={spinStyles}*/}
                        {/*    onChange={(event, value) => {*/}
                        {/*        this.props.setReplicationWeeks(parseInt(value));*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Stack>
                </div>
            </Panel>
        )
    }
}

SaveDialogPanel.propTypes = {
    //Is the panel open
    open: PropTypes.bool,
    //Function to call on close button click
    onDismiss: PropTypes.func,

    //The save structure we're using
    getDataStructure: PropTypes.func,

};


