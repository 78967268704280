import React from 'react';
import {Link} from 'react-router-dom';
import {
    HeaderName,
} from 'carbon-components-react';


/**
 * Return a friendly name for the portal based on whether we're a student or staff member logging in
 */
function portalName(props) {
    if (props.isAdmin) {
        return "Staff Portal";
    } else if (props.isTutor) {
        return "Staff Portal";
    } else if (props.isStudent) {
        return "Student Portal";
    } else if (props.isEducator) {
        return "Educator Portal";
    } else {
        return "Portal";
    }
}

/**
 * Update the home link based on whether we're logged in or not.
 * If we're logged in use the authenticated home link.
 */
function portalLinkTarget(authenticated) {
    if (authenticated) {
        return "/a";
    } else {
        return "/";
    }
}

const HeaderHome = (props) => (
    <HeaderName element={Link} to={portalLinkTarget(props.isLoggedIn)} prefix="AECC">
        {portalName(props)}
    </HeaderName>
);

export default HeaderHome;