import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
    Panel,
    SpinButton, Stack,
} from "@fluentui/react";


import {Button, ButtonSet} from "carbon-components-react";


/**
 * Overrides some fluent defaults - this is surprisingly difficult to do in css much easier here
 */
const spinStyles = {spinButtonWrapper: {width: 80}};

/** override the default gap between elements, this is abusing the MS override system rather than have to implement the
 *  full stylesheet
 **/
const stackProps = {childrenGap: "1rem"};



export default class ReplicateWeekPanel extends Component {

    constructor(props) {
        super(props);
    }

    footerContent = () => {
        return (
            <ButtonSet className="positionRight">
                <Button type="primary" onClick={() => {
                    this.props.onReplicate();
                    this.props.onDismiss();
                }}>
                    Replicate
                </Button>
            </ButtonSet>
        )
    }

    render() {
        return (
            <Panel
                isOpen={this.props.open}
                onDismiss={this.props.onDismiss}
                isBlocking={true}
                closeButtonAriaLabel="Close Panel"
                onRenderFooterContent={this.footerContent}
                isFooterAtBottom={true}
                isLightDismiss={true}
            >
                <div className="panelContainer">
                    <h3>Replicate current week</h3>
                    <p>If your institution operates a weekly schedule you can replicate the current weeks settings
                        across multiple weeks. Once replicated you can changes to each week individually without those being replicated back.</p>

                    <Stack tokens={stackProps}>
                        <SpinButton
                            label="Weeks to replicate *"
                            defaultValue={8}
                            min={1}
                            max={52}
                            step={1}
                            incrementButtonAriaLabel="Increase value by 1"
                            decrementButtonAriaLabel="Decrease value by 1"
                            styles={spinStyles}
                            onChange={(event, value) => {
                                this.props.setReplicationWeeks(parseInt(value));
                            }}
                        />
                    </Stack>
                </div>
            </Panel>
        )
    }
}

ReplicateWeekPanel.propTypes = {
    //Is the panel open
    open: PropTypes.bool,
    //Function to call on close button click
    onDismiss: PropTypes.func,
    //function passed in that handles the week replication
    setReplicationWeeks: PropTypes.func,
    //session value we store the number of weeks to replicate in
    replicationWeeks: PropTypes.number,
    //function called when we're requested to replicate
    onReplicate: PropTypes.func,
};


