import {Route, Routes} from "react-router-dom";

import CapacityLayout from "./layouts/CapacityLayout";
import CapacityModel from "./content/CapacityModel";

import DefaultLayout from "./layouts/DefaultLayout";
import Welcome from "./content/Welcome";


import './styles/app.scss'

function App() {
  return (
    <div className="App">

      <Routes>

        <Route path="/" element={<DefaultLayout/>}>
          <Route index element={<Welcome/>}/>
        </Route>


        {/* Matts Capacity Model  */}
        <Route path="/public/capacityModel" element={<CapacityLayout/>}>
          <Route index element={<CapacityModel/>} />
        </Route>


        <Route path="/qr" element={<DefaultLayout/>}>
          <Route index element={<Welcome/>}/>
        </Route>




      </Routes>



    </div>
  );
}

export default App;
