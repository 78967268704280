import {Component} from "react";
import {
    createTheme,
    ThemeProvider
} from "@fluentui/react";



const elementTheme = createTheme({

    defaultFontStyle: {
        fontFamily: '"IBM Plex Sans", "Helvetica Neue", Arial, sans-serif',
        fontWeight: 'regular'
    },

    palette: {
        themePrimary: '#005EB8',
        themeLighterAlt: '#f9f3fa',
        themeLighter: '#e6d2eb',
        themeLight: '#d0aeda',
        themeTertiary: '#a46cb4',
        themeSecondary: '#7f3c91',
        themeDarkAlt: '#642775',
        themeDark: '#542163',
        themeDarker: '#3e1849',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#b0b0b0',
        neutralSecondary: '#979797',
        neutralPrimaryAlt: '#7d7d7d',
        neutralPrimary: '#171717',
        neutralDark: '#4a4a4a',
        black: '#303030',
        white: '#ffffff',
    }});










export class FluentTheme extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <ThemeProvider theme={elementTheme}>
                {this.props.children}
            </ThemeProvider>
        );
    }
}


export default FluentTheme;