import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {

    Dropdown,

    Panel,
    SpinButton, TextField, TimePicker
} from "@fluentui/react";


import {Button, ButtonSet} from "carbon-components-react";
import {Stack} from '@fluentui/react/lib/Stack';
import LabelWithInfo from "../Util/LabelWithInfo";
import BasePanel from "../Util/BasePanel";

/**
 * Set up some sane defaults for our form
 */
const defaultState = {
    serviceName: "",
    serviceTitle: "",
    serviceType: "Ultrasound",
    serviceTypeIncrement: 0,

    defaultStudentCount: 1,
    defaultObserverCount: 0,
    usedStudentCount: 0,

    defaultSessionLength: 8,
    defaultSessionStart: 36,
}

/**
 * Overrides some fluent defaults - this is surprisingly difficult to do in css much easier here
 */
const spinStyles = {spinButtonWrapper: {width: 80}};

/** override the default gap between elements, this is abusing the MS override system rather than have to implement the
 *  full stylesheet
 **/
const stackProps = {childrenGap: "1rem"};

const timeOptions = [
    {key: '0', text: '00:00'},
    {key: '1', text: '00:15'},
    {key: '2', text: '00:30'},
    {key: '3', text: '00:45'},
    {key: '4', text: '01:00'},
    {key: '5', text: '01:15'},
    {key: '6', text: '01:30'},
    {key: '7', text: '01:45'},
    {key: '8', text: '02:00'},
    {key: '9', text: '02:15'},
    {key: '10', text: '02:30'},
    {key: '11', text: '02:45'},
    {key: '12', text: '03:00'},
    {key: '13', text: '03:15'},
    {key: '14', text: '03:30'},
    {key: '15', text: '03:45'},
    {key: '16', text: '04:00'},
    {key: '17', text: '04:15'},
    {key: '18', text: '04:30'},
    {key: '19', text: '04:45'},
    {key: '20', text: '05:00'},
    {key: '21', text: '05:15'},
    {key: '22', text: '05:30'},
    {key: '23', text: '05:45'},

    {key: '24', text: '06:00'},
    {key: '25', text: '06:15'},
    {key: '26', text: '06:30'},
    {key: '27', text: '06:45'},

    {key: '28', text: '07:00'},
    {key: '29', text: '07:15'},
    {key: '30', text: '07:30'},
    {key: '31', text: '07:45'},

    {key: '32', text: '08:00'},
    {key: '33', text: '08:15'},
    {key: '34', text: '08:30'},
    {key: '35', text: '08:45'},

    {key: '36', text: '09:00'},
    {key: '37', text: '09:15'},
    {key: '38', text: '09:30'},
    {key: '39', text: '09:45'},

    {key: '40', text: '10:00'},
    {key: '41', text: '10:15'},
    {key: '42', text: '10:30'},
    {key: '43', text: '10:45'},

    {key: '44', text: '11:00'},
    {key: '45', text: '11:15'},
    {key: '46', text: '11:30'},
    {key: '47', text: '11:45'},

    {key: '48', text: '12:00'},
    {key: '49', text: '12:15'},
    {key: '50', text: '12:30'},
    {key: '51', text: '12:45'},

    {key: '52', text: '13:00'},
    {key: '53', text: '13:15'},
    {key: '54', text: '13:30'},
    {key: '55', text: '13:45'},

    {key: '56', text: '14:00'},
    {key: '57', text: '14:15'},
    {key: '58', text: '14:30'},
    {key: '59', text: '14:45'},

    {key: '60', text: '15:00'},
    {key: '61', text: '15:15'},
    {key: '62', text: '15:30'},
    {key: '63', text: '15:45'},

    {key: '64', text: '16:00'},
    {key: '65', text: '16:15'},
    {key: '66', text: '16:30'},
    {key: '67', text: '16:45'},

    {key: '64', text: '16:00'},
    {key: '65', text: '16:15'},
    {key: '66', text: '16:30'},
    {key: '67', text: '16:45'},

    {key: '68', text: '17:00'},
    {key: '69', text: '17:15'},
    {key: '70', text: '17:30'},
    {key: '71', text: '17:45'},

    {key: '72', text: '18:00'},
    {key: '73', text: '18:15'},
    {key: '74', text: '18:30'},
    {key: '75', text: '18:45'},

    {key: '76', text: '19:00'},
    {key: '77', text: '19:15'},
    {key: '78', text: '19:30'},
    {key: '79', text: '19:45'},

    {key: '80', text: '20:00'},
    {key: '81', text: '20:15'},
    {key: '82', text: '20:30'},
    {key: '83', text: '20:45'},

    {key: '84', text: '21:00'},
    {key: '85', text: '21:15'},
    {key: '86', text: '21:30'},
    {key: '87', text: '21:45'},

    {key: '85', text: '22:00'},
    {key: '86', text: '22:15'},
    {key: '87', text: '22:30'},
    {key: '88', text: '22:45'},

    {key: '89', text: '23:00'},
    {key: '89', text: '23:15'},
    {key: '89', text: '23:30'},
    {key: '90', text: '23:45'},

]




const availableServicesTooltip = {
    "onRenderContent": () => (
        <div className = "tooltipContainer">
            <p>How you would identify this service, it will be used throughout to indicate which service is active</p>
        </div>
    ),
};


const serviceTypeTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>Indicate what type of activity takes place in this service. You may wish to define multiple ultrasound suites for example, but they'd all be of type Ultrasound.</p>
        </div>
    ),
};


const selectStartTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>You have an option for setting the default start time for your service, This will be used when quickly populating services on the grid.</p>
        </div>
    ),



}


export default class ServiceDefinitionPanel extends BasePanel {

    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    /**
     * Determines what the n is in this is the n'th <serviceType> service we have enabled
     * Note this is a max value so if lower digit services are removed we just keep incrementing not
     * re-issuing, ie its not a count of active services, just the next highest unused number
     */
    calculateIncrement(serviceType) {

        //Filter our service list to match those of just this serviceType (use array reference 1 due to this being an object conversion
        let theseServices = Object.entries(this.props.services).filter(service => service[1].serviceType === serviceType)

        //For each of the relevant services go through and pull out the max serviceTypeIncrement
        let newIncrement = 0;
        const incrementList = theseServices.map((service) => service[1].serviceTypeIncrement);

        if (incrementList.length === 0) {
        } else {
            newIncrement = Math.max(...incrementList) + 1;
        }

        return newIncrement;
    }


    /**
     * Generates the relevant title based on the shortform of the service type and supplied increment
     */
    genTitle(serviceType, increment) {
        const thisService = this.props.serviceTypes.filter((service) => service.key === serviceType)[0].shortForm;
        return thisService + String(increment).padStart(2,'0');
    }


    /**
     * Handles the addition of a new service by the end user
     */
    addService() {

        const { serviceName, defaultStudentCount, defaultObserverCount, defaultSessionLength, serviceType, usedStudentCount, defaultSessionStart} = this.state;

        //determine the peak increment for this service type
        let newIncrement = this.calculateIncrement(serviceType)

        let serviceTitle = this.genTitle(serviceType, newIncrement);



        let serviceDetails = {
            text: serviceName,
            serviceType: serviceType,
            defaultStudentCount: defaultStudentCount,
            defaultObserverCount: defaultObserverCount,
            defaultSessionLength: defaultSessionLength,
            serviceTypeIncrement: newIncrement,
            serviceTypeKey: serviceType,
            sessionCount: 0,
            usedStudentCount: usedStudentCount,
            defaultSessionStart: defaultSessionStart,
        }


        this.props.setServices((prev) => {

        //    const { [serviceName]: serviceDetails } = prev
        //    serviceDetails.count = serviceDetails.count + 1;
            return {
                ...prev,
                [serviceTitle]: serviceDetails,
            }
        })


        //Clear the form now we've completed things
        this.clearForm();

        //toggle the visibility of the form
        this.props.onDismiss();
    }

    /**
     * Resets the form elements to default states
     */
    clearForm() {
        this.setState(defaultState)
    }






    /**
     * The content to be displayed in the panel footer
     */
    footerContent = () => {
        return (
        <ButtonSet className="positionRight">
            <Button type = "button" onClick = {()=>{
                this.addService();

            }}>
                Add service
            </Button>
        </ButtonSet>
    )
    }











    render() {
        return (
            <Panel
                isOpen={this.props.open}
                onDismiss={this.props.onDismiss}
                isBlocking={false}
                closeButtonAriaLabel="Close Panel"
                onRenderFooterContent={this.footerContent}
                isFooterAtBottom={true}
                isLightDismiss={true}
            >
                <div className="panelContainer">
                <h3>
                    Add new service
                </h3>
                <div >
                    <p>
                        Add a new service to your availability list using the form below. The questions will define the default session information for your service. you can override these on an instance by instance basis.
                    </p>

                    <div className = "maxWidth">

                        <Stack tokens={stackProps}>
                    <Dropdown
                        onRenderLabel={this.labelRender}
                        label="New service type"
                        defaultSelectedKey="Ultrasound"
                        tooltip={serviceTypeTooltip}
                        options={this.props.serviceTypes}
                        disabled={false}
                        onChange = {(event, value) => {
                            this.setState({
                                serviceType: value.key
                            })
                        }}
                        required
                    />



                    <TextField
                        onRenderLabel={this.labelRender}
                        label="Service name"
                        tooltip={availableServicesTooltip}
                        disabled={false}
                        onChange = {(event, value) => {
                            this.setState({
                                serviceName: value
                            })
                        }}
                        required
                    />


                            <p>The following values are used to define the defaults for this service, how many students can you accomodate in a typical session based on your typical staffing level etc and for how many hours does a session run</p>

                    <SpinButton
                        onRenderLabel={this.labelRender}
                        tooltip={availableServicesTooltip}

                        label="Default student capacity *"
                        defaultValue={1}
                        min={0}
                        max={10}
                        step={1}
                        incrementButtonAriaLabel="Increase value by 1"
                        decrementButtonAriaLabel="Decrease value by 1"
                        styles={spinStyles}
                        onChange={(event, value) => {
                            this.setState( {
                                defaultStudentCount: value
                            })
                        }}
                    />


                            <SpinButton
                                onRenderLabel={this.labelRender}
//                                tooltip={availableServicesTooltip}

                                label="Default observer capacity *"
                                defaultValue={0}
                                min={0}
                                max={10}
                                step={1}
                                incrementButtonAriaLabel="Increase value by 1"
                                decrementButtonAriaLabel="Decrease value by 1"
                                styles={spinStyles}
                                onChange={(event, value) => {
                                    this.setState( {
                                        defaultObserverCount: value
                                    })
                                }}
                            />



                    <SpinButton
                        label="Default session length *"
                        defaultValue={8}
                        min={1}
                        max={24}
                        step={1}
                        incrementButtonAriaLabel="Increase value by 1"
                        decrementButtonAriaLabel="Decrease value by 1"
                        styles={spinStyles}
                        onChange={(event, value) => {
                            this.setState( {
                                defaultSessionLength: value
                            })
                        }}
                    />



                            <Dropdown
                                onRenderLabel={this.labelRender}
                                //styles={timePickerStyles}
                                label="Select Start Time"
                                defaultSelectedKey="36"
                                tooltip={selectStartTooltip}
                                options={timeOptions}
                                disabled={false}
                                onChange = {(event, value) => {


                                    this.setState({
                                        defaultSessionStart: value.key
                                    })
                                }}
                                required
                            />






                           {/* <TimePicker*/}
                           {/*     placeholder="Select start time"*/}
                           {/*     styles={timePickerStyles}*/}
                           {/*  //  useHour12*/}
                           {/*     allowFreeform*/}
                           {/*     autoComplete="on"*/}
                           {/*     increments={15}*/}

                           {/*     label="Default session start time"*/}
                           {/*     onChange={(event, value) => {*/}

                           {/*         alert("Value" + value);*/}

                           {/*         this.setState( {*/}
                           {/*             defaultSession2Length: value*/}
                           {/*         })*/}
                           {/*     }}*/}
                           {/*     dateAnchor={basicDateAnchor}*/}
                           {/*     timeRange={timeRange}*/}
                           {/*/>*/}
                        </Stack>

                        <p>&nbsp;</p>

                    </div>



                </div>






                </div>
            </Panel>


        )


    }
}

ServiceDefinitionPanel.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    setServices: PropTypes.func,
    services: PropTypes.object,
    serviceTypes: PropTypes.array,


/*
    onOverwrite: PropTypes.func,
    onMerge: PropTypes.func,
    onCancel: PropTypes.func,

    title: PropTypes.string,
    subText: PropTypes.string,

    open: PropTypes.any,

    children: PropTypes.node
*/
};


