import React, {Component} from 'react';
import {
    Header,
    HeaderContainer,
    HeaderMenuButton,

    SkipToContent,
} from 'carbon-components-react';


import HeaderNavigation from "carbon-components-react/lib/components/UIShell/HeaderNavigation";
import HeaderHome from "../../content/util/HeaderHome";


class DefaultDisplayHeader extends Component {

    constructor(props) {
        super(props);


    }

    componentDidMount() {


    }


    render() {

        return (

            <HeaderContainer
                render={({isSideNavExpanded, onClickSideNavExpand}) => (
                    <Header aria-label="Header">
                        <SkipToContent/>

                        <HeaderMenuButton
                            aria-label="Open menu"
                            onClick={onClickSideNavExpand}
                            isActive={isSideNavExpanded}
                        />

                        <HeaderHome isLoggedIn={false} isTutor={false} isStudent={false}/>

                        <HeaderNavigation aria-label="Header">

                        </HeaderNavigation>







                    </Header>
                )}
            />
        );
    }


}

export default DefaultDisplayHeader;
