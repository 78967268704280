import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter } from "react-router-dom";
import { initializeIcons } from '@fluentui/react/lib/Icons';

import './styles/index.scss';
import App from './App';

import FluentTheme from "./styles/FluentTheme";


initializeIcons();


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
                <FluentTheme>
                    <App />
                </FluentTheme>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
