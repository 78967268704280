import React, {Component} from 'react';
import { Content } from "carbon-components-react";
import { useNavigate} from "react-router-dom";







class Welcome extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isSubmitting: false,
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
        }
    }


    componentDidMount() {

    }

    render() {

        const {isSubmitting, displayNotification, notificationTitle, notificationSubtitle} = this.state;

        return (
            <>
                <div className="mainHeader">
                    <h1>Welcome</h1>
                </div>
                <Content>
                    <div className="bx--grid bx--grid--full-width ">

                        <div className="bx--row ">
                            <div className="bx--col-lg-16">
                                <p className="welcome">This page is intentionally blank (apps.aecc.ac.uk)</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="bx--row ">
                            <div className="bx--col-lg-16">
                                <div>

                                </div>
                            </div>
                        </div>


                        <div className="bx--row ">
                            <div className="bx--col-lg-16">

                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();

    return <Welcome {...props} navigate={navigate}/>;
}