import React, {Component} from 'react';

import {Outlet} from "react-router-dom";
import DefaultDisplayHeader from "./DefaultDisplayHeader";


/**
 * HAndles the Page themeing determining whether we are staff or student and updating the state for the navigation brs
 *
 *
 */
class DefaultLayout extends Component {



    componentDidMount() {


    }


    render() {
        return (
            <>
                <DefaultDisplayHeader/>


                <Outlet />
            </>
        );
        }
}


export default DefaultLayout;
