import {Stack} from "@fluentui/react/lib/Stack";
import {Callout, DefaultButton, IconButton, TooltipDelay, TooltipHost} from "@fluentui/react";
import * as PropTypes from "prop-types";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {Information} from "@carbon/icons-react/next";
import React from "react";









export default function LabelWithInfo(props) {


    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const descriptionId = useId('description');
    const iconButtonId = useId('iconButton');

    let labelText = props.label;

    let tooltip = props.tooltip

    if (props.required) {
        labelText = labelText + " *"
    }


        return (
            <>
                <div className = "ms-Label">
                    <span>{labelText}</span>
                    <TooltipHost
                        tooltipProps={tooltip}

                  //      onRenderContent={()=>{renderThis("stuff")}}
                        delay={TooltipDelay.zero}
//                      id={useId('available services')}
                    >
                    <Information className = "informationIcon" size={16} />
                </TooltipHost>
              </div>
            </>
        );
    };





LabelWithInfo.propTypes = {
    children: PropTypes.node,

    label: PropTypes.string,
};