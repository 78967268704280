/** React standard functions **/
import React, { useCallback, useEffect, useRef, useState} from 'react';

/** Carbon components and icons **/
import { Column, Grid, Row, Button, ButtonSet } from "carbon-components-react";
import {
    Add,
    DocumentExport,
    DocumentImport,
    Draggable,
    Information,
    DataVis_4,
    Replicate,
    User,
    UserFilled, Download, Notebook
} from "@carbon/icons-react/next";

/** Fluent ui components **/
import {
    SpinButton,
    Toggle,
    TooltipDelay,
    TooltipHost,
    TextField, DefaultButton,
} from "@fluentui/react";
import FluentTheme from "./FluentTheme";
import { useId, useBoolean } from '@fluentui/react-hooks';

/** String format routines **/
import {generateShortTimeString} from '../util/StringFormat';


/** JSPDF **/
import {
    jsPDF
} from "jspdf";



/** Luxon date/time functions **/
import { DateTime } from 'luxon'

/** React-big-calendar **/
import {Calendar, Views, luxonLocalizer} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

/** Our own capacityModel components **/
import ServiceDefinitionPanel from "./Components/Panels";
import WarningDialog from "../../components/WarningDialog";
import ReplicateWeekPanel from "./Components/Panels/ReplicateWeekPanel";
import ConfirmDialog from "../../components/ConfirmDialog";
import AddCourseLayoutPanel from "./Components/Panels/AddCourseLayoutPanel";
import LoadDialogPanel from "./Components/Panels/LoadDialogPanel";
import SaveDialogPanel from "./Components/Panels/SaveDialogPanel";
import {GaugeChart} from "@carbon/charts-react";


const gaugeOptions = {
 //  title: "Gauge semicircular -- danger status",
    resizable: false,
    height: "14px",
    width: "100%",
    gauge: {
        type: "semi",
        arcWidth: "3",
        alignment: "",
        status: "danger",
        deltaArrow: {
            enabled: false
        }
    },
    color: {
        gradient: {
            colors:['#ffffff','#000000'],
            enabled: true
        },
    },
    legend: {
        enabled: false
    },
    toolbar: {
        enabled: false
    },
    tooltip: {
        enabled: false
    }
}


// color: {
//     gradient: {
//         colors:['#ffffff','#000000'],
//         enabled: false
//     },
// },


//Set First Day of the week to monday, if we change this need to inspect the logic for week replication to handle
//the possibilities of overwriting weeks
const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 })

/** override the default width for the spin buttons, this is abusing the MS override system rather than have to
 *  implement the full stylesheet */
const spinStyles = {spinButtonWrapper: {width: 80}};

/** Tooltip Definitions **/
const availableServicesTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>An available service is either a room or facility you're able to host a placement in. This could be an individual room or a group of rooms if they share the same availability</p>

            <p>Drag a service onto the calender to create a default service period</p>
            <p>Select a service to highlight all instances of this service and allow creation of new instances by dragging them out on to the calendar</p>
            <p>Use the + option to add a new service to the list</p>
        </div>
    ),
};

const overallCapacitiesTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>This panel represents your capacity to take learners and the approximate load factor this wil place on your facilities.</p>
            <p>To select a different course and see your potential capacities use the drop down to the right</p>
        </div>
    ),
}

const selectedSessionsTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>This panel represents the currently selected event</p>
            <p>You can adjust the learner capacity of this event below</p>
        </div>
    ),
}

const serviceAvailabilityMatrixTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>Use the calendar below to indicate when a particular service is available.</p>
            <p>Select an available service on the right and either drag the service onto the calender or drag on the calendar to paint your availability</p>

        </div>
    ),
};


const filterByModalityTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>Set filters to display only the modalities selected</p>
        </div>
    )
}


/** Default structure of a course **/

const courseStructure = {
    location: "AECC",
    placementLengthWeeks: 10,
    services: {
        Ultrasound: 8,
        MRI: 8,
        XRay: 240,
        CT: 32,
        Theatre: 40,
        Fluoroscopy: 16,
        Nuclear: 8,
        Mammography: 8,
        Other: 40,
    }
}


const defaultCourses = [
    courseStructure,
]




/** List of our available service types **/
const defaultServiceTypes = [
    {key: 'Ultrasound', text: 'Ultrasound', shortForm: 'US', studentMinutes: 0 , openMinutes: 0, inUse: false},
    {key: 'MRI', text: 'MRI', shortForm: 'MRI', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'XRay', text: 'XRay', shortForm: 'XRAY', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'CT', text: 'CT', shortForm: 'CT', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'Theatre', text: 'Theatre', shortForm: 'TH', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'Fluoroscopy', text: 'Fluoroscopy and interventional', shortForm: 'FL', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'Nuclear', text: 'Nuclear Medicine', shortForm: 'NM', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'Mammography', text: 'Mammography', shortForm: 'MM', studentMinutes: 0, openMinutes: 0, inUse: false},
    {key: 'Other', text: 'Other experience', shortForm: 'OTH', studentMinutes: 0, openMinutes: 0, inUse: false},
]












/** List of default services for our demo **/
const defaultServices = {
    // mri: {
    //     text: "MRI",
    //     serviceTypeKey: 'MRI',
    //     sessionCount: 0,
    //     defaultSessionLength: 8,
    //     defaultStudentCount: 2,
    // },
    // xray: {
    //     text: "X-Ray",
    //     serviceTypeKey: 'XRay',
    //     sessionCount: 0,
    //     defaultSessionLength: 8,
    //     defaultStudentCount: 2,
    // },
    // ultrasound: {
    //     text: "Ultrasound",
    //     serviceTypeKey: 'Ultrasound',
    //     sessionCount: 0,
    //     defaultSessionLength: 8,
    //     defaultStudentCount: 2,
    // },
};

/** Capacity Model is a React front end application for demonstrating capacity and availability to externals looking
 * to offer student placements
 * */
export default function CapacityModel() {

    /** Are we showing dragged items */
    const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true)

    /** Panel and dialog triggers **/
    const [isServiceDefinitionPanelVisible, { toggle: toggleServiceDefinitionPanelVisibility }] = useBoolean(false);
    const [isReplicationDialogVisible, { toggle: toggleReplicationDialogVisibility }] = useBoolean(false);
    const [isWarningDialogVisible, { toggle: toggleWarningDialogVisibility }] = useBoolean(false);


    /**
     * Dialogs for:
     *
     * generating a new event
     * replicating the current week across x future weeks
     * defining a class
     */
    const [isEventDialogVisible, { toggle: toggleEventDialogVisibility }] = useBoolean(false);
    const [isReplicationWeekPanelVisible, { toggle: toggleReplicationWeekPanelVisibility }] = useBoolean(false);
    const [isCourseLayoutDisplayVisible, { toggle: toggleAddCourseLayoutDisplay }] = useBoolean(false)
    /**
     * Our load/save dialogs
     */
    const [isSaveDialogVisible, { toggle: toggleSaveDialogVisibility }] = useBoolean(false)
    const [isLoadDialogVisible, { toggle: toggleLoadDialogVisibility }] = useBoolean(false)

    /**
     *  Confirmation dialogs
     */
    const [isSaveSuccessDialogVisible, { toggle: toggleSaveSuccessDialogVisibility }] = useBoolean(false)
    const [isLoadSuccessDialogVisible, { toggle: toggleLoadSuccessDialogVisibility }] = useBoolean(false)
    const [isLoadFailedDialogVisible, { toggle: toggleLoadFailedDialogVisibility }] = useBoolean(false)


    /** Service selected in the left hand panel **/
    const [selectedService, setSelectedService] = useState(null);

    /** events list **/
    const [events, setEvents] = useState([]);
    const [viewableEvents, setViewableEvents] = useState([]);
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    const [showOnlySelectedModality, setShowOnlySelectedModality] = useState(false);





    /** course types **/
    const [courses, setCourses] = useState(defaultCourses)
    const [selectedCourse, setSelectedCourse] = useState(0)

    /** service types **/
    const [serviceTypes, setServiceTypes] = useState(defaultServiceTypes)
    /** current services list **/
    const [services, setServices] = useState(defaultServices)
    /** Where we're selecting modalities fo display, this holds the object indicating whether they are in fact selected **/
    const [modalitySelections, setModalitySelections] = useState({});


    /** Calendar View settings and defaults, we listen to changes to these to handle some onscreen updates **/
    const [date, setDate] = useState( new Date()  )
    const [view, setView] = useState( Views.WEEK )

    /** On screen form element value **/
    const [replicationWeeks, setReplicationWeeks] = useState(8);

    /** Selected event */
    const [selectedEvent, setSelectedEvent] = useState(null);

    //Used by the calendar to handle DnD events
    const [draggedEvent, setDraggedEvent] = useState();

    const [sessionLearnerCount, setSessionLearnerCount] = useState(1);
    const [sessionObserverCount, setSessionObserverCount] = useState(0);



    const [sessionNote, setSessionNote] = useState("");



    //Holds the running totals for display purposes
    const [minutesAvailable, setMinutesAvailable] = useState(0);
    const [hoursAvailableString, setHoursAvailableString] = useState("0 Hours 0 Minutes");

    const [learnerMinutesAvailable, setLearnerMinutesAvailable] = useState(0);
    const [learnerHoursAvailableString, setLearnerHoursAvailableString] = useState("0 Hours 0 Minutes");

    const [observerMinutesAvailable, setObserverMinutesAvailable] = useState(0);



    const [placementsAvailable, setPlacementsAvailable] = useState(0);
    const [placementsAvailableString, setPlacementsAvailableString] = useState("0");

    //Set up our Calendar component
    const DnDCalendar = withDragAndDrop(Calendar);





    // const onToggleSelect = React.useCallback(
    //     (ev, item) => {
    //         ev && ev.preventDefault();
    //
    //         if (item) {
    //             setSelection({ ...selection, [item.key]: selection[item.key] === undefined ? true : !selection[item.key] });
    //         }
    //     },
    //     [selection],
    // );



    //What happens when we toggle one of the selected modalities
//     const onToggleSelect = (ev, item) => {
//             ev && ev.preventDefault();
//
//             console.log("Item selection");
//             console.log(item);
//
//             if (item) {
//                 setModalitySelections({ ...modalitySelections, [item.key]: modalitySelections[item.key] === undefined ? true : !modalitySelections[item.key] });
//             }
//
//             //We want to hide any entries for display that are not part of the selected modalities.
//
// //            toggleModalitySelections()
//
//         }
//
//
//
//     const modalitySelectorProps =
//         {
//             shouldFocusOnMount: true,
//             items: [
//                 // { key: defaultServiceTypes[0].key, text: defaultServiceTypes[0].text, canCheck: (defaultServiceTypes[0].openMinutes > 0) , isChecked: modalitySelections[defaultServiceTypes[0].key], onClick: onToggleSelect },
//                 // { key: defaultServiceTypes[1].key, text: defaultServiceTypes[1].text, canCheck: true, isChecked: modalitySelections[defaultServiceTypes[1].key], onClick: onToggleSelect },
//                 // { key: defaultServiceTypes[2].key, text: defaultServiceTypes[2].text, canCheck: true, isChecked: modalitySelections[defaultServiceTypes[2].key], onClick: onToggleSelect },
//             ],
//         };







   // const [dataStructure, setDataStructure] = useState({});



    /**
     * Handles state updates for the calendar, triggered on changes to the selected data in the ui
     */
    const onNavigate = useCallback((newDate) => {
            setDate(newDate)
            resetSelectedService();
        }
        , [setDate])


    const resetSelectedService = () => {
        setSelectedService(null);
        setSelectedEvent(null);
        if (!isEventDialogVisible) {
            toggleEventDialogVisibility();
        }
    }


    const onView = useCallback((newView) => {
        setView(newView)
//        highlightSelectedServices()
    }, [setView])



    const setEventCapacity = (selectedEvent, newCapacity) => {
        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === selectedEvent.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== selectedEvent.id)
            return [...filtered, { ...existing, studentCount: newCapacity }]
        })
    }


    const setEventObserverCapacity = (selectedEvent, newCapacity) => {
        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === selectedEvent.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== selectedEvent.id)
            return [...filtered, { ...existing, observerCount: newCapacity }]
        })
    }


    const setEventNote = (selectedEvent, newNote) => {
        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === selectedEvent.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== selectedEvent.id)
            return [...filtered, { ...existing, note: newNote }]
        })

    }



const getReport = () => {

        let pdf = new jsPDF('portrait', 'mm', 'a4');




    pdf.html(document.getElementById('pdfStuff'), {
        callback: function (pdf) {
            pdf.save();
        },
        margin: 0,
        autoPaging: 'text',
        filename: 'report.pdf',
        x: 0,
        y: 0,
        width: 210,
        windowWidth: 1440

    });
}





    /**
     * Returns the data structure for our save
     */
    const getDataStructure = () => {
        let dataStructure = {
                1: {
                    services: services,
                    serviceTypes: serviceTypes,
                    events: events,
                    courses: courses
                }
        }
        return dataStructure;
    }


    const getGaugeData = (placementsAvailableCount, hoursExpected, minutesAvailable) => {
        let minutesUtilised = placementsAvailableCount * hoursExpected * 60;

        let utilisationPercentage = 0;

        if ((parseInt(minutesAvailable) != NaN && minutesAvailable > 0)  && (parseInt(minutesUtilised) != NaN && minutesUtilised > 0) ) {
            utilisationPercentage = ((minutesUtilised / minutesAvailable) * 100)
            utilisationPercentage = ((minutesUtilised / minutesAvailable) * 100)
        }

        return [
            {
                group: "value",
                value: utilisationPercentage
            }
        ]
    }


    {/*{ parseInt(placementsAvailable) != NaN && placementsAvailable > 0 && courseStructure.services[serviceTypeDetails.key] > 0 && (*/}
    {/*    <>*/}


    {/*        {((((placementsAvailable * courseStructure.services[serviceTypeDetails.key] ) * 60  ) /  serviceTypeDetails.studentMinutes  ) * 100).toFixed(1) }*/}


    {/*    </>*/}
    {/*)}*/}
    /**
     * We have to recalculate a bunch of counters whenever the events array changes, as the calendar can make changes
     * and fundamentally owns the events we're doing so as a listener here
     */
    useEffect(() => {
        //Calculates the total hours available
        calculateHoursAvailable();

        //Updates the service counters
        calculateServiceCounters(events);

        //Calculates the placements available given the selected placement pattern
        calculatePlacements(events);

    }, [events, selectedCourse]);


    /**
     * keep viewable events in sync with events based off of the main events list
     */
    useEffect(() => {

        //Only display the modalities selected in modality selector unless show only slected is enabled in which case
        //show that service only

        if (showOnlySelectedModality) {
            setViewableEvents(events.filter(event=>event.serviceTypeKey === services[selectedService].serviceType       ))
        } else if (showOnlySelected) {
            setViewableEvents(events.filter(event=>event.service === selectedService ))
        } else {
            setViewableEvents(events);
        }
    }, [events, showOnlySelected, selectedService, showOnlySelectedModality]);










    /**
     * We're trying to implement support for highlighting entries this is proving problematic given the way the calendar
     * manages events.
     *
     * We;re going to watch for a state change in the selected facility and trigger action based off of that
     */
    useEffect(() => {
        highlightSelectedServices()
    }, [selectedService]);



    /** When a new service type is added to the available services, make the service type available in the service type filters **/
//     useEffect(() => {
//         //generate the se
//
//
//         console.log("in adding service setup");
//
//
//         console.log("services");
//         console.log(services);
//
//         console.log("serviceTypes");
//         console.log(serviceTypes);
//
//
//
//         let updatedServiceTypes = serviceTypes;
// //        let updatedServiceTypes = [];
// //        let items = modailitySelectorProps.items;
//
//         for (let element in services) {
//
//             console.log("in initial loop");
//             console.log(services[element].serviceTypeKey);
//
//             updatedServiceTypes.find(service => service.key === services[element].serviceTypeKey).inUse = true;
//
// //            updatedServiceTypes[services[element].serviceTypeKey].inUse = true;
//             console.log("setting inUse true" + services[element].serviceTypeKey);
//         }
//
//         let newItems = []
//
//         for (let i = 0; i < updatedServiceTypes.length; i++) {
//             //Go through our services and if we have one marked as in use add it to the list for the modality selector
//
//             if (updatedServiceTypes[i].inUse) {
//                 let item = {
//                     key: updatedServiceTypes[i].key,
//                     text: updatedServiceTypes[i].text,
//                     canCheck: true,
//                     isChecked: true,
//                     onClick: onToggleSelect
//                 }
//                 newItems.push(item);
//             }
//         }
//
//         modalitySelectorProps.items = newItems;
//         setServiceTypes(updatedServiceTypes);
//
//     }, [services]);










    /** Ensures the styles of the active servicetype are correct if we're creating a new event that matches them **/
    const eventPropGetter = useCallback(
        (event) => (
            {
                ...(
                    { className: (event.service === selectedService ? 'selectedService' :  '') }
                ),
            }),
        []
    )

    /*
    const eventPropGetter = useCallback(
        (event) => (
            {
                ...(event.isDraggable
                    ? { className: 'isDraggable' }
                    : { className: 'nonDraggable' }),
            }),
        []
    )
    */


    /** Called on the selection of a service, handles the changing of the gui to reflect this and setting the
     * correct element in the state
     */
    const highlightSelectedServices = () => {

        const serviceDivs = document.querySelectorAll("div#selectableServiceContainer div.selectableService");

        let serviceName = selectedService;
        if (serviceName === null) {
            serviceDivs.forEach(serviceDiv => {
                serviceDiv.classList.remove("serviceSelected")
            });
            const testDivs = document.querySelectorAll("div.rbc-events-container div.rbc-event");
            testDivs.forEach((div) => {
                div.classList.remove("selectedService");
            })
        } else {
            //Remove the selected flag from all
            serviceDivs.forEach(serviceDiv => {
                serviceDiv.classList.remove("serviceSelected")
            });
            //Add the selected flag to the selected div
            document.getElementById(serviceName).classList.add("serviceSelected");

            //Returns all of our events
            const allEvents = document.querySelectorAll("div.rbc-events-container div.rbc-event");

            //iterate around the divs we want to highlight those that match our servicetype and remove highlight from those that don't
            //TODO is there a gilter way of improving performance here

            allEvents.forEach((div) => {
                if (div.title.endsWith(`[${serviceName}]`)) {
                    div.classList.add("selectedService");
                } else {
                    div.classList.remove("selectedService");
                }
            })
        }



    }


    /**
     * calculates the total time used for all of our events. Note the temptation is to use event segments here
     * but the timeslice is not updated by the calendar upon resize events. Instead parse the start and end dates
     * to calculate the difference
     **/
    const calculateHoursAvailable = () => {
        let totalOpenMinutes = 0;
        let totalLearnerMinutes = 0;
        let totalObserverMinutes = 0;

        //Iterate through our events list and calculate the event durations and sum thereof, learnerMinutes available and sum thereof
        for (let i = 0; i < events.length; i++ ) {
            let startDate = events[i].start;
            let endDate = events[i].end;

            let duration = DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate)).as("minutes");



            totalOpenMinutes += duration;
            totalLearnerMinutes += duration * events[i].studentCount;
            totalObserverMinutes += duration * (events[i].observerCount !== undefined ? events[i].observerCount: 0);
        }


        setMinutesAvailable(totalOpenMinutes);
        setHoursAvailableString(generateShortTimeString(totalOpenMinutes));

        setLearnerMinutesAvailable(totalLearnerMinutes);
        setLearnerHoursAvailableString(generateShortTimeString(totalLearnerMinutes));

        setObserverMinutesAvailable(totalObserverMinutes);


    }






    /**
     * Called when an existing event is dragged
     */
    const onEventDrop = useCallback(
        ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {

            const { allDay } = event
            if (!allDay && droppedOnAllDaySlot) {
                event.allDay = true
            }
            setEvents((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev) => ev.id !== event.id)
                return [...filtered, { ...existing, start, end, allDay }]
            })
            highlightSelectedServices()
        },
        [setEvents, selectedService]
    )



    const onEventResize = useCallback(
        ({ event, start, end }) => {
            setEvents((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev) => ev.id !== event.id)
                return [...filtered, { ...existing, start, end }]
            })
        },
        [setEvents, selectedService]
    )


    /**
     * Listens to the draggedEvent state and would handle those events that
     * don't conform to our expected patterns
     */
    const onDragOver = useCallback((dragEvent) => {
            if (draggedEvent !== 'undroppable') {
                dragEvent.preventDefault()
            }
        },
        [draggedEvent, selectedService]
    )



    const newEvent = useCallback(
        (event) => {

            if (event.action === "select" || event.action ==="click") {
                //This is a drag out on screen event use the existing end date and set the type to the current selectedService

                if (selectedService === null) {
                    //We're interacting with the calender with no service selected, display warning
                    toggleWarningDialogVisibility();
                    return;
                } else {
                    event.title = formatFacilityTitle(services[selectedService].text, selectedService)
                    event.service = selectedService
                    event.studentCount = services[selectedService].defaultStudentCount
                    event.observerCount = services[selectedService].defaultObserverCount


                    event.note = ""
                    event.serviceTypeKey = services[selectedService].serviceTypeKey
                }
            }


            setEvents((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}

                //Get a list of ids
                const idList = prev.map((item) => item.id)

                let newId = 0;
                if (idList.length === 0) {

                } else {
                    newId = Math.max(...idList) + 1;
                }

                let newEvents = [...prev, { ...event, id: newId }]

                return newEvents;

            })
            highlightSelectedServices()
        },
        [setEvents, selectedService]
    )



    /** returns the number of placements available given the event state and the selected placement template **/
        //TODO we need to think about the available rooms here, can't have 1 student getting all their time simultaneously in multiple rooms

    const calculatePlacements = (events) => {
            let limitingFactor = null;

            setServiceTypes( prev => {

                let updatedServiceTypes = prev.filter((serviceType) => {
                    return true;
                });

                //For each serviceType calculate the possible number of placeable students for that service

                updatedServiceTypes.forEach((serviceType) => {



                    if (courses[selectedCourse].services[serviceType.key] > 0) {

                        //This is included in the course requirements
                        let potentialStudents = Math.trunc(serviceType.studentMinutes / (courses[selectedCourse].services[serviceType.key] * 60))

                        if (limitingFactor === null || potentialStudents < limitingFactor ) {
                            limitingFactor = potentialStudents;
                        }
                        //Sets a potential max for this serviceType if it is the limiting factor.
                        serviceType.potentialStudents = potentialStudents;
                    } else {
                        serviceType.potentialStudents = -1;
                    }
                })

                if (limitingFactor === null) {

                    setPlacementsAvailableString("0");
                    setPlacementsAvailable(0);

                } else {
                    setPlacementsAvailableString(limitingFactor);
                    setPlacementsAvailable(limitingFactor);

                }

                return updatedServiceTypes;

            })

            setPlacementsAvailable(limitingFactor);
        }




    /**
     * Take the supplied events list and calculate the total offerings of these services
     */
    const calculateServiceCounters = useCallback( (events) => {

            //Generate a clone to prevent weird render issues
            setServiceTypes( prev => {

                let updatedServiceTypes = prev.filter((serviceType) => {
                    return true;
                });

                updatedServiceTypes.forEach((serviceType) => {
                    //Use the event List and count up a:) the number of hours, b) the representative studentHours

                    //    let dateInView = DateTime.fromJSDate(date);
                    let dateInView = DateTime.now();
                    let targetDatePeriodStart = dateInView.startOf("week");
                    let targetDatePeriodEnd = dateInView.plus({weeks: courses[selectedCourse].placementLengthWeeks}).endOf("week");



                    let relevantEvents = events.filter((event) => {
                        if ( (event.serviceTypeKey === serviceType.key) && (event.start > targetDatePeriodStart && event .end < targetDatePeriodEnd)) {
                            return true;
                        } else {
                            return false;
                        }
                    })

                    let studentMinutes = 0;
                    let serviceOpenTime = 0;

                    relevantEvents.forEach(event => {
                        let eventTimeDelta = DateTime.fromJSDate(event.end).diff(DateTime.fromJSDate(event.start)).as("minutes");
                        serviceOpenTime = serviceOpenTime + eventTimeDelta;
                        let studentMinutesDelta = eventTimeDelta * event.studentCount;
                        studentMinutes = studentMinutes + studentMinutesDelta;
                    })
                    serviceType.studentMinutes = studentMinutes;
                    serviceType.openMinutes = serviceOpenTime;
                })
                return updatedServiceTypes;
            })

        }, [events,serviceTypes]
    )

    const clickRef = useRef(null)


    /** Handles the onclick event for events, should display the info panel **/
    const onSelectEvent = useCallback(( event) => {
        /** Wait 300 ms before doing anything in case this is th first click in a double click **/
        window.clearTimeout(clickRef?.current)
        clickRef.current = window.setTimeout(() => {
            //window.alert( "Alert event selected" + event  +  'onSelectEvent')
            if (!isEventDialogVisible) {
                toggleEventDialogVisibility();
            }
            setSelectedEvent(event);
            setSessionLearnerCount(event.studentCount);
            setSessionObserverCount( event.observerCount !== undefined ? event.observerCount : 0  );

            setSessionNote(event.note)


        }, 300)
    }, [selectedService, isEventDialogVisible])


    const deleteEvent = useCallback((event) => {
        setEvents(events.filter(item => item.id !== event.id));
    }, [events, selectedService]);




    /**
     * Formats the title of our services
     *
     */
    const formatFacilityTitle = (name, serviceId) => {
        return `${name} [${serviceId}]`
    }



    const getHourFromSegment = (segment) => {


        let hour = 0;
        //old style entry
        if (segment === undefined )  {
            hour = 8;
        } else {
            hour = Math.trunc(segment / 4);
        }
     //   alert("Returning: " + hour + " from:" + segment);
        return hour;
    }


    const getMinutesFromSegment = (segment) => {

            let minutes = 0;
        //old style entry
              if (minutes === undefined )  {
                  minutes = 8;
              } else {
                  minutes= (segment % 4) * 15 ;
              }
  //      alert("Returning: " + minutes + " from:" + segment);
        return minutes;
    }


    /**
     * Handles the dropping of a service event onto the calendar called by the calendar when it receives a drop
     */
    const onServiceDrop = useCallback(({ start, end, allDay: isAllDay }) => {

            //If we have flagged something to be ignored ignore it
            if (draggedEvent === 'undroppable') {
                setDraggedEvent(null)
                return
            }

            const { serviceName, title } = draggedEvent

            let calculatedStart = start;

            //If we're a new save we should have a defaultSessionStart to override the drop location
            if (services[serviceName].defaultSessionStart !== undefined) {
                calculatedStart = DateTime.fromJSDate(start).set({
                    hour: getHourFromSegment(services[serviceName].defaultSessionStart),
                    minute: getMinutesFromSegment(services[serviceName].defaultSessionStart)
                }).toJSDate()
            }




            const event = {
                //Title of the event

                title: formatFacilityTitle(title, serviceName),
                //Start time of the event - we want to use the date part of this but override it with our default start times
                //By default the start time is where the drop occurs but we're overriding it.

//                start,
                start: calculatedStart,

                //By default end will be + 15minutes after the start but we want to override this to give us + default session length

                end: DateTime.fromJSDate(calculatedStart).plus({hours: services[serviceName].defaultSessionLength}).toJSDate(),
                //If the event was dropped on the all day section
                isAllDay,
                //service name
                service: serviceName,

                studentCount: services[serviceName].defaultStudentCount,
                usedStudentCount: services[serviceName].usedStudentCount,
                observerCount: services[serviceName].defaultObserverCount,

                note: "",
                serviceTypeKey: services[serviceName].serviceTypeKey
            }

            //reset the dragged events
            setDraggedEvent(null)

            setServices((prev) => {

                const { [serviceName]: serviceDetails } = prev
                serviceDetails.sessionCount = serviceDetails.sessionCount + 1;
                return {
                    ...prev,
                    [serviceName]: serviceDetails,
                }
            })
            newEvent(event)
        },
        [draggedEvent, services, setDraggedEvent, setServices, newEvent, selectedService]
    )

    /**
     * We've been asked to replicate the events, check that we're not overwriting anything, if we are thow a warning and
     * ask to continue
     */
    const replicateWeekCheck = () => {

        //As we're replicating weeks we need to determine the start and end date sof the relevant weeks to ensure theres
        //no conflicts
        let dateInView = DateTime.fromObject(date);

        //returns week start of a monday
        let targetDatePeriodStart = dateInView.plus({weeks: 1}).startOf("week");
        let targetDatePeriodEnd = dateInView.plus({weeks: replicationWeeks + 1}).endOf("week");

        //Crude check of events in the future time period, not wholly complete for lengthy events crossing the time
        //period, but we can revisit that if needed
        let collisionFound = false;
        for (let i = 0; i < events.length; i++ ) {
            let startDate = DateTime.fromJSDate(events[i].start);

            //Check if an event starts in a given week
            if (startDate > targetDatePeriodStart && startDate < targetDatePeriodEnd) {
                //We have a collision
                collisionFound = true;
            }
        }

        //if no collision has been found we can replicate the events otherwise ask the question
        if (collisionFound) {
            toggleReplicationDialogVisibility()
        } else {
            replicateWeek()
        }
    }

    /** Handles the replication of the selected days' weeks data across the following x weeks**/
    const replicateWeek = () => {

        //If the confirmation dialog is open close it
        if (isReplicationDialogVisible) {
            toggleReplicationDialogVisibility();
        }

        //We're going to be generating some new entries so we need to figure out a suitable id number for these
        let newId = 0

        const idList = events.map((event) => event.id)
        if (idList.length === 0) {
        } else {
            newId = Math.max(...idList) + 1;
        }

        let dateInView = DateTime.fromObject(date);
        let sourceDatePeriodStart = dateInView.startOf("week");
        let sourceDatePeriodEnd = dateInView.endOf("week");


        //Clone the events array to avoid re-renders whilst we add events
        setEvents( prev => {
            let newEvents = prev.filter((event) => {
                return true;
            });

            newEvents.forEach(event => {
                //Is the event in our current week
                if (event.start > sourceDatePeriodStart && event.start < sourceDatePeriodEnd) {
                    //This is an event we should replicate
                    for (let i = 1; i < replicationWeeks + 1; i++) {
                        //Clone the existing event
                        let newEvent = {...event};
                        newEvent.start = DateTime.fromJSDate(event.start).plus({week: i}).toJSDate();
                        newEvent.end = DateTime.fromJSDate(event.end).plus({week: i}).toJSDate();
                        newEvent.id = newId;
                        newId++;
                        newEvents.push(newEvent)
                    }
                }
            })

            return newEvents;
        })
    }

    /** Clear out existing events in the week to be overwritten before commencing replication **/
    const overwriteWeek = () => {
        //remove the events that aren't in the current week
        let dateInView = DateTime.fromObject(date);

        //returns week start of a monday
        let targetDatePeriodStart = dateInView.plus({weeks: 1}).startOf("week");
        let targetDatePeriodEnd = dateInView.plus({weeks: replicationWeeks + 1}).endOf("week");

        //Crude check for events in and out of our target periods, doesn't account for events events crossing borders
        //or existing over the entire period but if we need those we can revisit this
        setEvents((prev) => {
            let newEvents = prev.filter((event) => {
                if (event.start > targetDatePeriodStart && event.start < targetDatePeriodEnd) {
                    return false;
                } else {
                    return true ;
                }
            });
            return newEvents;
        })
        //We've now cleared out the existing events so start our replication
        replicateWeek()
    }

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent])

    const customOnDragOver = useCallback((dragEvent) => {
            // check for undroppable is specific to this example
            // and not part of API. This just demonstrates that
            // onDragOver can optionally be passed to conditionally
            // allow draggable items to be dropped on cal, based on
            // whether event.preventDefault is called
            if (draggedEvent !== 'undroppable') {
                console.log('preventDefault')
                dragEvent.preventDefault()
            }
        },
        [draggedEvent, selectedService]
    )


    /**
     * Handles the dragging of a service onto the calendar and adds the event to the state
     * As the state is used as a dependency for other items this triggers their activation
     **/
    const handleDragStart = useCallback((event) => setDraggedEvent(event), [selectedService])




    /**
     * represents the rendering of an event in the calendar
     */
    const Event = useCallback((event) => {

        if (event.event.note && event.event.note.length > 0 && view === "month") {
            return (
                <span className="eventMonth">
                <strong>
                    <Notebook className = "notebookIconMonth" size = {16} />{event.title}
                </strong>
            </span>
            )
        } else if (event.event.note && event.event.note.length > 0 ) {
            return (
                <div className = "eventTitle">
                    <Notebook className = "notebookIcon" size = {24} />
                    <strong>
                        {event.title}
                    </strong>

                    <div className = "eventLearners">Learners: {event.event.studentCount}</div>
                    <div className = "eventLearners">Observer: {(event.event.observerCount) !== undefined ? event.event.observerCount : 0}</div>
                </div>

            )
        } else if (view === "month") {
            return (
                <span className = "eventMonth">
                    <span className = "nudgeTitle">{event.title}</span>
                </span>
            )
        } else {
            return (
                <div className = "eventTitle">
                    <strong>{event.title}</strong>
                    <div className = "eventLearners">Learners: {event.event.studentCount}</div>
                    <div className = "eventLearners">Observer: {(event.event.observerCount) !== undefined ? event.event.observerCount : 0}</div>
                </div>
            )
        }
    }, []
    )





    const getSessionCountValue = (selectedEvent) => {
        return selectedEvent.studentCount;
    };




    return (

        <>
            <FluentTheme>
                <Grid className="noPadding">
                    <Row>
                        <Column>
                            <h3 class="sectionHeading"></h3>
                        </Column>
                    </Row>
                </Grid>

                <form>
                    <Grid className="noPadding">

                        <Row className = "extendBottom">
                            <Column className="capacityColumn">

                                <div className="cap_formPanel">
                                    <h3 className="cpaf">Available services
                                        <TooltipHost
                                            tooltipProps={availableServicesTooltip}
                                            delay={TooltipDelay.zero}
                                            id={useId('available services')}
                                        >
                                            <Information className = "informationIcon" size={16} />
                                        </TooltipHost>

                                        <span className="menuButton" onClick={toggleServiceDefinitionPanelVisibility} >
                                            <Add size = {24} />
                                        </span>

                                    </h3>
                                    <div className="formRow">

                                        <div id = "selectableServiceContainer">
                                            {Object.entries(services).map(([serviceName, serviceDetails]) => (
                                                <div className="selectableService {}"
                                                     id = {serviceName}
                                                     draggable="true"
                                                     key={serviceName}
                                                     onDragStart={() => handleDragStart({ title: serviceDetails.text, serviceName })}
                                                     onClick={() => {
                                                         setSelectedService(serviceName);
                                                     }}

                                                >
                                                    <div className = "selectableServiceTitleContainer">
                                                        {formatFacilityTitle(serviceDetails.text, serviceName)}
                                                    </div>
                                                    <div className = "selectableServiceOptionsContainer">
                                                        <div className = "selectableServiceDropCount">
                                                            {serviceDetails.sessionCount}
                                                        </div>

                                                        {/*
                                                    <div className = "selectableServiceEditContainer">
                                                        <Edit />
                                                    </div>

                                                    <div className = "selectableServiceEditContainer">
                                                        <RowDelete size={16} />
                                                    </div>

*/}
                                                        <div className = "selectableServiceEditContainer">&nbsp;</div>
                                                        <div className = "selectableServiceDragContainer">
                                                            <Draggable />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <Toggle
                                            label="Show only selected service"
                                            inlineLabel={true}
                                            className="inlineToggleRight"
                                            ariaLabel="Show only selected service"
                                            description="Show only selected service"
                                            onText="Yes"
                                            offText="No"
                                            onChange={(event, value) => {
                                                setShowOnlySelectedModality(false);
                                                setShowOnlySelected(value);
                                            }}
                                            checked={showOnlySelected}
                                            disabled={false}
                                        /><br/>
                                        <Toggle
                                            label="Show only selected modality"
                                            inlineLabel={true}
                                            className="inlineToggleRight"
                                            ariaLabel="Show only selected modality"
                                            description="Show only selected modality"
                                            onText="Yes"
                                            offText="No"
                                            onChange={(event, value) => {
                                                setShowOnlySelected(false);
                                                setShowOnlySelectedModality(value);
                                            }}
                                            checked={showOnlySelectedModality}
                                            disabled={false}
                                        />
                                    </div>
                                </div>

                                <p>&nbsp;</p>


                                {/*<div className="cap_formPanel">*/}

                                {/*    <h3 className="cpaf">Filter by modality*/}
                                {/*        <TooltipHost*/}
                                {/*            tooltipProps={filterByModalityTooltip}*/}
                                {/*            delay={TooltipDelay.zero}*/}
                                {/*            id={useId('filterByModality')}*/}
                                {/*        >*/}
                                {/*            <Information className = "informationIcon" size={16} />*/}
                                {/*        </TooltipHost>*/}

                                {/*        <span className="menuButton2" onClick={toggleAddCourseLayoutDisplay} >*/}
                                {/*            <Add size = {24} />*/}
                                {/*        </span>*/}
                                {/*    </h3>*/}
                                {/*</div>*/}

                                {/*<p>&nbsp;</p>*/}

                                        <div className="cap_formPanel">

                                    <h3 className="cpaf">Overall capacities
                                        <TooltipHost
                                            tooltipProps={overallCapacitiesTooltip}
                                            delay={TooltipDelay.zero}
                                            id={useId('overallCapacities')}
                                        >
                                            <Information className = "informationIcon" size={16} />
                                        </TooltipHost>

                                        <span className="menuButton2" onClick={toggleAddCourseLayoutDisplay} >
                                            <Add size = {24} />
                                        </span>



                                    </h3>
                                    <hr className = "sectionBreak"/>

                                    <div className = "targetCourseContainer" >
                                              <span className="ms-Label targetCourseTitle">Target course:</span>
                                                <select id = "selectedCourse" className = "targetCourse" onChange={()=>{
                                                    setSelectedCourse(document.getElementById("selectedCourse").value)



                                                }}>
                                                {courses &&
                                                    courses.map((course, index) => (
                                                        <option value={index}>{course.location} {index}</option>
                                                    ))}
                                                </select>
                                        </div>




                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <span className = "ms-Label">Total operational hours:</span>
                                            </td>

                                            <td>
                                                {hoursAvailableString}
                                            </td>

                                        </tr>




                                        <tr>
                                            <td>
                                                <span className = "ms-Label">Total learner hours:</span>
                                            </td>

                                            <td>
                                                {generateShortTimeString(learnerMinutesAvailable)}
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <span className = "ms-Label">Total observer hours:</span>
                                            </td>

                                            <td>
                                                {generateShortTimeString(observerMinutesAvailable)}
                                            </td>

                                        </tr>


                                        <tr>
                                            <td>
                                                <span className = "ms-Label">Placements available:</span>
                                            </td>

                                            <td>
                                                {placementsAvailableString}
                                            </td>

                                        </tr>

                                        </tbody>
                                    </table>

                                    <hr className = "sectionBreak"/>

                                    <table className="capacityTable">
                                        <tbody>
                                        {Object.entries(serviceTypes).map(([serviceTypeIndex, serviceTypeDetails]) => (
                                            <>
                                                {courses[selectedCourse].services[serviceTypeDetails.key] > 0 && (


                                            <tr>
                                                <td width = "33%">
                                                    {serviceTypeDetails.key}
                                                </td>

                                                {/*<td width = "20%" align="left">*/}
                                                {/*    {serviceTypeDetails.potentialStudents >= 0 && (*/}
                                                {/*        <>*/}
                                                {/*            <UserFilled size={16} /> {placementsAvailable}*/}
                                                {/*        </>*/}
                                                {/*    ) }*/}
                                                {/*</td>*/}

                                                <td width = "29%" align="left">
                                                    {generateShortTimeString(serviceTypeDetails.openMinutes)}
                                                </td>



                                                <td width = "20%" className = "paddingLeft" align="left">
                                                    <User className = "informationIconRight"  size={16}/> {serviceTypeDetails.potentialStudents}
                                                </td>

                                                <td  width = "10%" align="center">
                                                    <GaugeChart
                                                        data={getGaugeData(placementsAvailable, courses[selectedCourse].services[serviceTypeDetails.key], serviceTypeDetails.studentMinutes)}
                                                        options={gaugeOptions}>
                                                    </GaugeChart>

                                                </td>

                                                <td  width = "8%" align="right">

                                                    <TooltipHost
                                                        content={
                                                        <>
                                                            <table>
                                                                <tbody>
                                                                <tr>
                                                                    <td><span className = "ms-Label">Hours per placement</span></td>
                                                                    <td>{courseStructure.services[serviceTypeDetails.key]} hours</td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={2}><hr/></td>
                                                                </tr>
                                                                    <tr>
                                                                        <td><span className = "ms-Label">Service operating time</span></td>
                                                                        <td>{generateShortTimeString(serviceTypeDetails.openMinutes)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span className = "ms-Label">Placement hours available</span></td>
                                                                        <td>{generateShortTimeString(serviceTypeDetails.studentMinutes)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2}><hr/></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span className = "ms-Label">Unused placement time</span></td>
                                                                        <td>
                                                                            {generateShortTimeString(serviceTypeDetails.studentMinutes - (placementsAvailable * courses[selectedCourse].services[serviceTypeDetails.key] * 60))}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </>
                                                        }
                                                        delay={TooltipDelay.zero}
                                                        id={"aaa"}
                                                    >
                                                        <DataVis_4 className = "informationIconRight" size={16} />
                                                    </TooltipHost>

                                                </td>
                                            </tr>
                                                )  }
                                            </>
                                        ))}


                                        </tbody>
                                    </table>


                                    <hr className = "sectionBreak"/>

                            <table width = "100%">
                                <tbody>
                                    <tr>
                                        <td align = "right">


                                            <ButtonSet className="positionRight">
                                                <Button type = "button" onClick = {()=>{
                                                    getReport()
                                                }}>
                                                     Report <Download className = "downloadIcon" size = {16}/>
                                                </Button>
                                            </ButtonSet>





                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                                </div>



                            </Column>

                            <Column  className="filesystemContent">
                                <h3 className="capacityTitle">Service availability matrix

                                    <TooltipHost
                                        tooltipProps={serviceAvailabilityMatrixTooltip}
                                        delay={TooltipDelay.zero}
                                        id={useId('serviceAvailabilityMatrix')}
                                    >
                                        <Information className = "informationIcon" size={16} />
                                    </TooltipHost>

                                    {/*<span className = "selectModalityButton">*/}
                                    {/*    <DefaultButton text="Modality selector" menuProps={modalitySelectorProps} />*/}

                                    {/*                                        <TooltipHost*/}
                                    {/*                                            tooltipProps={filterByModalityTooltip}*/}
                                    {/*                                            delay={TooltipDelay.zero}*/}
                                    {/*                                            id={useId('filterByModalityTooltip')}*/}
                                    {/*                                        >*/}
                                    {/*    <Information className = "informationIcon" size={16} />*/}
                                    {/*</TooltipHost>*/}


                                    {/*</span>*/}



                                    <span className="menuButton" onClick={toggleReplicationWeekPanelVisibility} >
                                           replicate <Replicate className = "informationIcon" size = {16} />
                                    </span>
                                    <span className="menuButton" onClick={toggleSaveDialogVisibility} >
                                            Save <DocumentExport size = {16} />
                                    </span>
                                    <span className="menuButton" onClick={toggleLoadDialogVisibility} >
                                            Load <DocumentImport size = {16} />
                                    </span>

                                </h3>


                                <div>
                                    <DnDCalendar
                                        defaultDate={date}
                                        defaultView={Views.WEEK}
                                        resourceIdAccessor="resourceId"
                                        resourceTitleAccessor="serviceTypeKey"
                                        date={date}
                                        onEventDrop={onEventDrop}
                                        onDragOver={onDragOver}
                                        onSelectSlot={newEvent}
                                        onEventResize={onEventResize}
                                        onSelectEvent={onSelectEvent}

                                        //Triggered when date changes
                                        onNavigate={onNavigate}
                                        //Triggered when view type changes
                                        onView={onView}
                                        view={view}

                                        eventPropGetter={eventPropGetter}

                                        resizable={true}
                                        selectable={true}
                                        onDoubleClickEvent={deleteEvent}

                                        showMultiDayTimes={true}

                                        localizer={localizer}
                                        events={viewableEvents}
                                        startAccessor="start"
                                        endAccessor="end"
                                        step={15}
                                        timeslots={8}
                                        style={{ height: 700 }}
                                        views={['month', 'week', 'day'  ]}

                                        //      tooltipAccessor={displayTooltip}




                                        components={ {
                                            event: Event,
                                            //   toolbar: Toolbar,
                                        }}


                                        dragFromOutsideItem={
                                            displayDragItemInCell ? dragFromOutsideItem : null
                                        }
                                        onDropFromOutside={onServiceDrop}

                                    />
                                </div>
                            </Column>
                        </Row>
                    </Grid>
                </form>

                <LoadDialogPanel
                    open={isLoadDialogVisible}
                    onDismiss={toggleLoadDialogVisibility}

                    setCourses={setCourses}
                    setServices={setServices}
                    setServiceTypes={setServiceTypes}
                    setEvents={setEvents}

                />

                <SaveDialogPanel
                    open={isSaveDialogVisible}
                    onDismiss={toggleSaveDialogVisibility}
                    getDataStructure={getDataStructure}
                />


                <ServiceDefinitionPanel
                    open={isServiceDefinitionPanelVisible}
                    onDismiss={toggleServiceDefinitionPanelVisibility}
                    setServices={setServices}
                    services={services}
                    serviceTypes={serviceTypes}
                />

                <ReplicateWeekPanel
                    open={isReplicationWeekPanelVisible}
                    onDismiss={toggleReplicationWeekPanelVisibility}
                    replicationWeeks={replicationWeeks}
                    setReplicationWeeks={setReplicationWeeks}
                    onReplicate={replicateWeekCheck}
                />

                <AddCourseLayoutPanel
                    open={isCourseLayoutDisplayVisible}
                    onDismiss={toggleAddCourseLayoutDisplay}
                    setCourses={setCourses}
                    courses={courses}
                />

                <ConfirmDialog
                    title = "Confirm Replication"
                    subText = "There's a potential issue replicating these values"
                    open = {isReplicationDialogVisible}
                    setOpen = {toggleReplicationDialogVisibility}
                    onMerge = {replicateWeek}
                    onOverwrite = {overwriteWeek}
                    onCancel = {toggleReplicationDialogVisibility}
                >
                    <p>There's already some sessions in the weeks to be replicated onto. You can choose to overwrite these sessions, or cancel to investigate.</p>
                </ConfirmDialog>

                <WarningDialog
                    title = "No service selected"
                    subText = "Please select a service to enable dragging of elements"
                    open = {isWarningDialogVisible}
                    setOpen = {toggleWarningDialogVisibility}
                    onOk = {toggleWarningDialogVisibility}
                >
                    <p>You must select a service to enable drawing of service sessions on the calender</p>
                </WarningDialog>

                <WarningDialog
                    title = "Save underway"
                    subText = "your data has been saved"
                    open = {isSaveSuccessDialogVisible}
                    setOpen = {toggleSaveSuccessDialogVisibility}
                    onOk = {toggleSaveSuccessDialogVisibility}
                >
                    <p>You can find your save file on your default downloads folder. It will be named <strong>capacityModel.sav</strong></p>
                    <p>You can give this file to others to see your model or use it a later date to continue to work on your model</p>
                </WarningDialog>

                <WarningDialog
                    title = "Load underway"
                    subText = "your data has been imported"
                    open = {isLoadSuccessDialogVisible}
                    setOpen = {toggleLoadSuccessDialogVisibility}
                    onOk = {toggleLoadSuccessDialogVisibility}
                >
                    <p>The model has been updated based on your save file</p>
                </WarningDialog>

                <WarningDialog
                    title = "Load underway"
                    subText = "your data has been imported"
                    open = {isLoadFailedDialogVisible}
                    setOpen = {toggleLoadFailedDialogVisibility}
                    onOk = {toggleLoadFailedDialogVisibility}
                >
                    <p>We were unable to update the model based on your save file</p>
                </WarningDialog>


                    {isEventDialogVisible && (
                        <div className = "permanentFooterContainer">
                            <div className = "permanentFooter">

                            <div className="cap_formPanel">

                                <h3 className="cpaf">Selected session details
                                    <TooltipHost
                                        tooltipProps={selectedSessionsTooltip}
                                        delay={TooltipDelay.zero}
                                        id={"newId"}
                                    >
                                        <Information className = "informationIcon" size={16} />
                                    </TooltipHost></h3>

                                <table className = "sessionDetails">
                                    <tbody>


                                    {selectedEvent && selectedEvent !== null && (
                                        <tr>

                                        <td width = "25%">
                                            <div>Service: <span className="answer"> {selectedEvent.title} </span></div>
                                            <div>Start: <span className="answer">{DateTime.fromJSDate(selectedEvent.start).toLocaleString(DateTime.DATETIME_MED)}</span></div>
                                            <div>Ends: <span className="answer">{DateTime.fromJSDate(selectedEvent.end).toLocaleString(DateTime.DATETIME_MED)}</span></div>
                                            <div>Duration: <span className="answer">{generateShortTimeString(DateTime.fromJSDate(selectedEvent.end).diff(DateTime.fromJSDate(selectedEvent.start)).as("minutes"))}</span></div>
                                        </td>


                                        <td width = "20%"className="totalTitle">
                                                    <SpinButton
                                                        label={"learner count"}
                                                        value={sessionLearnerCount + ""}
                                                        min={0}
                                                        max={10}
                                                        step={1}
                                                        incrementButtonAriaLabel="Increase value by 1"
                                                        decrementButtonAriaLabel="Decrease value by 1"
                                                        styles={spinStyles}
                                                        onChange={(event, value) => {
                                                            setEventCapacity(selectedEvent, parseInt(value));
                                                        //    selectedEvent.studentCount = parseInt(value);
                                                            setSessionLearnerCount(parseInt(value));
                                                        }}
                                                    />


                                            <SpinButton
                                                label={"observer count"}
                                                value={sessionObserverCount + ""}
                                                min={0}
                                                max={10}
                                                step={1}
                                                incrementButtonAriaLabel="Increase value by 1"
                                                decrementButtonAriaLabel="Decrease value by 1"
                                                styles={spinStyles}
                                                onChange={(event, value) => {
                                                    setEventObserverCapacity(selectedEvent, parseInt(value));
                                                    //    selectedEvent.studentCount = parseInt(value);
                                                    setSessionObserverCount(parseInt(value));
                                                }}
                                            />


                                        </td>




                                        <td width = "55%">
                                            <TextField multiline rows = {3}
                                                       label = "Session notes:"
                                                       value = {sessionNote}
                                                       onChange = {(event, value) => {
                                                               setEventNote(selectedEvent, value);
                                                               setSessionNote(value);

                                                             //  selectedEvent.note = value;
                                                       }}
                                            />
                                        </td>
                                    </tr>
                                        )}


                                    {/*<tr>*/}
                                    {/*    <td colSpan="2" className="totalTitle">*/}
                                    {/*        {selectedEvent && selectedEvent !== null && (*/}
                                    {/*            <>{DateTime.fromJSDate(selectedEvent.start).toISO()}</>*/}
                                    {/*        )}*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}

                                    {/*<tr>*/}
                                    {/*    <td colSpan="2" className="totalTitle">*/}
                                    {/*        {selectedEvent && selectedEvent !== null && (*/}
                                    {/*            <>{DateTime.fromJSDate(selectedEvent.end).toISO()}</>*/}
                                    {/*        )}*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}


                                    </tbody>
                                </table>
                            </div>
                         </div>
                        </div>
                    )}




            </FluentTheme>



            <div className = "pdfStuffContainer">
                <div id = "pdfStuff" className = "pdfStuff">

                    <div id = "pageHeader" className = "mainLogoContainer">
                        <div className ="mainLogoContainerRestrictWidth">
                            <img className = "nhsLogo" src = "/i/radiographylearnercapacitytoolbannerhee.png" />
                            <img className = "aeccLogo" src = "/i/AECCLogo_inverse.png" />
                        </div>
                    </div>

                    <div className = "pageMargins">
                        <h3 className = "pdfSectionTitle">Overall Capacities</h3>

                        <table className = "pdfTable">
                            <tr>
                                <td className = "pdfQuestion">Targeted Course: </td>
                                <td className = "pdfAnswer"> {courses[selectedCourse].location}</td>
                            </tr>

                            <tr>
                                <td className = "pdfQuestion">Total operational time: </td>
                                <td className = "pdfAnswer">{hoursAvailableString}</td>
                            </tr>

                            <tr>
                                <td className = "pdfQuestion">Total learning hours available: </td>
                                <td className = "pdfAnswer">{generateShortTimeString(learnerMinutesAvailable)}</td>
                            </tr>

                            <tr>
                                <td className = "pdfQuestion">Total observer hours: </td>
                                <td className = "pdfAnswer">{generateShortTimeString(observerMinutesAvailable)}</td>
                            </tr>

                            <tr>
                                <td className = "pdfQuestion">Placements available: </td>
                                <td className = "pdfAnswer">{placementsAvailableString}</td>
                            </tr>
                        </table>

                        <table className="pdfCapacityTable">
                            <tbody>

                            <tr>
                                <th className = "pdfTableHeader" width = "15%">Service</th>
                                <th className = "pdfTableHeader" width = "15%">Expected hours/student</th>
                                <th className = "pdfTableHeader" width = "15%">Operational hours</th>
                                <th className = "pdfTableHeader" width = "15%">Potential learners</th>
                                <th className = "pdfTableHeader" width = "15%">Placement hours</th>
                                <th className = "pdfTableHeader" width = "10%">Utilisation</th>
                                <th className = "pdfTableHeader" width = "15%">Un-utilised hours</th>
                            </tr>



                            {Object.entries(serviceTypes).map(([serviceTypeIndex, serviceTypeDetails]) => (
                                <>
                                    {courses[selectedCourse].services[serviceTypeDetails.key] > 0 && (


                                        <tr>
                                            <td className = "pdfAnswer" >
                                                {serviceTypeDetails.key}
                                            </td>

                                                          <td className = "pdfAnswer" align = "center">
                                                {courseStructure.services[serviceTypeDetails.key]} hours
                                            </td>


                                            <td className = "pdfAnswer" align="center">
                                                {generateShortTimeString(serviceTypeDetails.openMinutes)}
                                            </td>



                                            <td className = "pdfAnswer" align="center">
                                                {serviceTypeDetails.potentialStudents}
                                            </td>


                                            <td className = "pdfAnswer" align="center">
                                                {generateShortTimeString(serviceTypeDetails.studentMinutes)}
                                            </td>



                                            <td className = "pdfAnswer" width = "10%" align="center">

                                                { serviceTypeDetails.studentMinutes > 0 ? (
<>
                                                {
                                                    (( (courseStructure.services[serviceTypeDetails.key] * 60 * placementsAvailableString ) / serviceTypeDetails.studentMinutes ) * 100).toFixed(2)
                                                } %
</>
                                                    ) : (
                                                    <>n/a</>
                                                    )
                                                }

                                            </td>

                                            <td className = "pdfAnswer" align = "center">
                                                 {generateShortTimeString(serviceTypeDetails.studentMinutes - (placementsAvailable * courses[selectedCourse].services[serviceTypeDetails.key] * 60))}
                                            </td>

                                        </tr>
                                    )  }
                                </>
                            ))}


                            </tbody>
                        </table>




                        <div className = "pageSectionBreak"></div>

                        {/*<h3 className="pdfSectionTitle">Sessions by time</h3>*/}

                        {/*<table>*/}

                        {/*/!*{events && events.map((event, index) => (*!/*/}
                        {/*/!*        <tr>*!/*/}
                        {/*/!*            <td>{event.title}</td>*!/*/}
                        {/*/!*            <td>{event.service}</td>*!/*/}
                        {/*/!*            <td>{event.studentCount}</td>*!/*/}
                        {/*/!*            <td>{event.note}</td>*!/*/}
                        {/*/!*            <td>{event.serviceTypeKey}</td>*!/*/}

                        {/*/!*            <td>{DateTime.fromJSDate(event.start).toLocaleString(DateTime.DATETIME_MED)}</td>*!/*/}
                        {/*/!*            <td>{DateTime.fromJSDate(event.end).toLocaleString(DateTime.DATETIME_MED)}</td>*!/*/}

                        {/*/!*            /!*let duration = DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate)).as("minutes");*!/*!/*/}
                        {/*/!*        </tr>*!/*/}

                        {/*/!*)) }*!/*/}
                        {/*</table>*/}


                    </div>



                </div>
            </div>

        </>
    );
}

/*
const PdfDocument = () => (
    <Document>

        <Page size="A4" >
            <View >
                <Text>Section #1</Text>
            </View>
            <View style={styles.section}>
                <Text>Section #2</Text>
            </View>
        </Page>

    </Document>
)


 */